<template>
  <div>
    <treatment-history-detail
      :backRoute="backRoute"
      patientDetailRoute="DoctorPatientDetail"
      treatmentHistoryDetailRoute="DoctorPatientTreatmentDetail"
    />
  </div>
</template>
<script>
import PatientTreatmentHistoryDetails from "../../general-component/treatment-history/TreatmentDetails2";
export default {
  name: "DoctorPatientTreatmentDetails",
  components: {
    "treatment-history-detail": PatientTreatmentHistoryDetails
  },
  data() {
    return {
      backRoute: "DoctorPatientDetail"
    };
  },
  created() {
    if (this.$route.query.backRoute) {
      this.backRoute = this.$route.query.backRoute;
    }
  },
  beforeRouteLeave(to, from, next) {
    const popup = document.getElementById("editPatientDetailsDrawer");
    if (popup && window.getComputedStyle(popup).display !== "none"){
      next(false);
    }
    else {
      next();
    }
  },
};
</script>
